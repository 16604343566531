<div class="cm-new-customer-setup-container">
  <mat-accordion>
    <mat-expansion-panel #panel0
                         [expanded]="_step() === 0"
                         (opened)="_onExpansionPanelOpened(0, panel0)"
                         (closed)="_onExpansionPanelClosed(0, panel0)"
                         hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="cm-form-group-title">
            Company Info <div class="cm-form-group-title-error">{{ _customerInfoFormGroup.invalid ? "*" : "" }}</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="cm-form-group-container"
           [formGroup]="_customerInfoFormGroup">
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Company Name</mat-label>
            <mf-input [formControl]="_customerInfoCompanyNameFormControl" />
            <mat-error
                       *ngIf="_customerInfoCompanyNameFormControl.invalid">{{_errorMessages[_customerInfoCompanyNameName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Doing Business As (DBA) Name</mat-label>
            <mf-input [formControl]="_customerInfoDoingBusinessAsFormControl" />
            <mat-error
                       *ngIf="_customerInfoDoingBusinessAsFormControl.invalid">{{_errorMessages[_customerInfoDoingBusinessAsName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Dun & Bradstreet (DUNS) Number</mat-label>
            <mf-input allowEmptyDisplayValue="true"
                      [type]="_inputTypes.int"
                      [formControl]="_customerInfoDunBradstreetNumberFormControl" />
            <mat-error
                       *ngIf="_customerInfoDunBradstreetNumberFormControl.invalid">{{_errorMessages[_customerInfoDunBradstreetNumberName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Website</mat-label>
            <mf-input [formControl]="_customerInfoWebsiteFormControl" />
            <mat-error
                       *ngIf="_customerInfoWebsiteFormControl.invalid">{{_errorMessages[_customerInfoWebsiteName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Telephone Number</mat-label>
            <mf-country-select matPrefix
                               #customerInfoTelephoneNumberCountrySelect></mf-country-select>
            <mf-phone-number-input [formControl]="_customerInfoTelephoneNumberFormControl"
                                   [countrySelect]="customerInfoTelephoneNumberCountrySelect"></mf-phone-number-input>
            <mat-error
                       *ngIf="_customerInfoTelephoneNumberFormControl.invalid">{{_errorMessages[_customerInfoTelephoneNumberName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Fax Number</mat-label>
            <mf-country-select matPrefix
                               #customerInfoFaxNumberCountrySelect></mf-country-select>
            <mf-phone-number-input [formControl]="_customerInfoFaxNumberFormControl"
                                   [countrySelect]="customerInfoFaxNumberCountrySelect"></mf-phone-number-input>
            <mat-error
                       *ngIf="_customerInfoFaxNumberFormControl.invalid">{{_errorMessages[_customerInfoFaxNumberName]}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button color="primary"
                mat-flat-button
                (click)="_nextStep()">Next</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel #panel1
                         [expanded]="_step() === 1"
                         (opened)="_onExpansionPanelOpened(1, panel1)"
                         (closed)="_onExpansionPanelClosed(1, panel1)"
                         hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="cm-form-group-title">
            Billing Address <div class="cm-form-group-title-error">{{ _billingAddressFormGroup.invalid ? "*" : ""
              }}</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div #panelContainer1
           class="cm-form-group-container"
           [formGroup]="_billingAddressFormGroup">
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Line 1</mat-label>
            <mf-input [formControl]="_billingAddressLine1FormControl" />
            <mat-error
                       *ngIf="_billingAddressLine1FormControl.invalid">{{_errorMessages[_billingAddressLine1Name]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Line 2</mat-label>
            <mf-input [formControl]="_billingAddressLine2FormControl" />
            <mat-error
                       *ngIf="_billingAddressLine2FormControl.invalid">{{_errorMessages[_billingAddressLine2Name]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Line 3</mat-label>
            <mf-input [formControl]="_billingAddressLine3FormControl" />
            <mat-error
                       *ngIf="_billingAddressLine3FormControl.invalid">{{_errorMessages[_billingAddressLine3Name]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>City</mat-label>
            <mf-input [formControl]="_billingAddressCityFormControl" />
            <mat-error
                       *ngIf="_billingAddressCityFormControl.invalid">{{_errorMessages[_billingAddressCityName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>State</mat-label>
            <mf-input [formControl]="_billingAddressStateFormControl" />
            <mat-error
                       *ngIf="_billingAddressStateFormControl.invalid">{{_errorMessages[_billingAddressStateName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Postal Code</mat-label>
            <mf-input [formControl]="_billingAddressPostalCodeFormControl" />
            <mat-error
                       *ngIf="_billingAddressPostalCodeFormControl.invalid">{{_errorMessages[_billingAddressPostalCodeName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <mf-input [formControl]="_billingAddressCountryFormControl" />
            <mat-error
                       *ngIf="_billingAddressCountryFormControl.invalid">{{_errorMessages[_billingAddressCountryName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-label>Type</mat-label>
          <mat-radio-group [formControl]="_billingAddressTypeFormControl">
            <mat-radio-button [value]="_addressTypes.residential">
              {{ _getAddressTypesDisplayValue(_addressTypes.residential) }}
            </mat-radio-button>
            <mat-radio-button [value]="_addressTypes.commercial">
              {{ _getAddressTypesDisplayValue(_addressTypes.commercial) }}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_billingAddressTypeFormControl.invalid">{{_errorMessages[_billingAddressTypeName]}}</mat-error>
        </div>
      </div>
      <mat-action-row>
        <button color="primary"
                mat-flat-button
                (click)="_prevStep()">Previous</button>
        <button color="primary"
                mat-flat-button
                (click)="_nextStep()">Next</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel #panel2
                         [expanded]="_step() === 2"
                         (opened)="_onExpansionPanelOpened(2, panel2)"
                         (closed)="_onExpansionPanelClosed(2, panel2)"
                         hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="cm-form-group-title">
            Shipping Address<div class="cm-form-group-title-error">{{ _shippingAddressFormGroup.invalid ? "*" : ""
              }}</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div #panelContainer2
           class="cm-form-group-container"
           [formGroup]="_shippingAddressFormGroup">
        <div class="cm-form-control-container">
          <mat-label>Same as Billing Address?</mat-label>
          <mat-radio-group [formControl]="_shippingAddressSameBillingFormControl">
            <mat-radio-button value="true">Yes (Same as Billing Address)</mat-radio-button>
            <mat-radio-button value="false">No (Different from Billing Address)</mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_shippingAddressSameBillingFormControl.invalid">{{_errorMessages[_shippingAddressSameBillingName]}}</mat-error>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Line 1</mat-label>
            <mf-input [formControl]="_shippingAddressLine1FormControl" />
            <mat-error
                       *ngIf="_shippingAddressLine1FormControl.invalid">{{_errorMessages[_shippingAddressLine1Name]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Line 2</mat-label>
            <mf-input [formControl]="_shippingAddressLine2FormControl" />
            <mat-error
                       *ngIf="_shippingAddressLine2FormControl.invalid">{{_errorMessages[_shippingAddressLine2Name]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Line 3</mat-label>
            <mf-input [formControl]="_shippingAddressLine3FormControl" />
            <mat-error
                       *ngIf="_shippingAddressLine3FormControl.invalid">{{_errorMessages[_shippingAddressLine3Name]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>City</mat-label>
            <mf-input [formControl]="_shippingAddressCityFormControl" />
            <mat-error
                       *ngIf="_shippingAddressCityFormControl.invalid">{{_errorMessages[_shippingAddressCityName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>State</mat-label>
            <mf-input [formControl]="_shippingAddressStateFormControl" />
            <mat-error
                       *ngIf="_shippingAddressStateFormControl.invalid">{{_errorMessages[_shippingAddressStateName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Postal Code</mat-label>
            <mf-input [formControl]="_shippingAddressPostalCodeFormControl" />
            <mat-error
                       *ngIf="_shippingAddressPostalCodeFormControl.invalid">{{_errorMessages[_shippingAddressPostalCodeName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Country</mat-label>
            <mf-input [formControl]="_shippingAddressCountryFormControl" />
            <mat-error
                       *ngIf="_shippingAddressCountryFormControl.invalid">{{_errorMessages[_shippingAddressCountryName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-label>Type</mat-label>
          <mat-radio-group [formControl]="_shippingAddressTypeFormControl">
            <mat-radio-button [value]="_addressTypes.residential">
              {{ _getAddressTypesDisplayValue(_addressTypes.residential) }}
            </mat-radio-button>
            <mat-radio-button [value]="_addressTypes.commercial">
              {{ _getAddressTypesDisplayValue(_addressTypes.commercial) }}
            </mat-radio-button>
            <mat-radio-button [value]="_addressTypes.freightForwarder">
              {{ _getAddressTypesDisplayValue(_addressTypes.freightForwarder) }}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_shippingAddressTypeFormControl.invalid">{{_errorMessages[_shippingAddressTypeName]}}</mat-error>
        </div>
        <div *ngIf="_showShippingAddressFreightForwarderNameFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Freight Forwarder Name</mat-label>
            <mf-input [formControl]="_shippingAddressFreightForwarderNameFormControl" />
            <mat-error
                       *ngIf="_shippingAddressFreightForwarderNameFormControl.invalid">{{_errorMessages[_shippingAddressFreightForwarderNameName]}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button color="primary"
                mat-flat-button
                (click)="_prevStep()">Previous</button>
        <button color="primary"
                mat-flat-button
                (click)="_nextStep()">Next</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel #panel3
                         [expanded]="_step() === 3"
                         (opened)="_onExpansionPanelOpened(3, panel3)"
                         (closed)="_onExpansionPanelClosed(3, panel3)"
                         hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="cm-form-group-title">
            Shipping Info<div class="cm-form-group-title-error">{{ _shippingInfoFormGroup.invalid ? "*" : "" }}</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div #panelContainer3
           class="cm-form-group-container"
           [formGroup]="_shippingInfoFormGroup">
        <div class="cm-form-control-container">
          <mat-label>Freight Handling Code</mat-label>
          <mat-radio-group [formControl]="_shippingInfoFreightHandlingCodeFormControl">
            <mat-radio-button [value]="_freightHandlingTypes.incora">
              {{_getFreightHandlingTypesDisplayValue(_freightHandlingTypes.incora)}}
            </mat-radio-button>
            <mat-radio-button [value]="_freightHandlingTypes.collect">
              {{_getFreightHandlingTypesDisplayValue(_freightHandlingTypes.collect)}}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_shippingInfoFreightHandlingCodeFormControl.invalid">{{_errorMessages[_shippingInfoFreightHandlingCodeName]}}</mat-error>
        </div>
        <div *ngIf="_showShippingInfoShippingMethodFormControl"
             class="cm-form-control-container">
          <mat-label>Shipping Method (Preferred Carrier)</mat-label>
          <mat-radio-group [formControl]="_shippingInfoShippingMethodFormControl">
            <mat-radio-button [value]="_shippingMethodsTypes.fedex">
              {{_getShippingMethodsTypesDisplayValue(_shippingMethodsTypes.fedex)}}
            </mat-radio-button>
            <mat-radio-button [value]="_shippingMethodsTypes.dhl">
              {{_getShippingMethodsTypesDisplayValue(_shippingMethodsTypes.dhl)}}
            </mat-radio-button>
            <mat-radio-button [value]="_shippingMethodsTypes.ups">
              {{_getShippingMethodsTypesDisplayValue(_shippingMethodsTypes.ups)}}
            </mat-radio-button>
            <mat-radio-button [value]="_shippingMethodsTypes.other">
              <mat-form-field>
                <mat-label>{{_getShippingMethodsTypesDisplayValue(_shippingMethodsTypes.other)}}</mat-label>
                <mf-input [formControl]="_shippingInfoShippingMethodOtherFormControl" />
                <mat-error
                           *ngIf="_shippingInfoShippingMethodOtherFormControl.invalid">{{_errorMessages[_shippingInfoShippingMethodOtherName]}}</mat-error>
              </mat-form-field>
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_shippingInfoShippingMethodFormControl.invalid">{{_errorMessages[_shippingInfoShippingMethodName]}}</mat-error>
        </div>
        <div *ngIf="_showShippingInfoShippingCarrierAcctFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Shipping Carrier Acct #</mat-label>
            <mf-input [formControl]="_shippingInfoShippingCarrierAcctFormControl" />
            <mat-error
                       *ngIf="_shippingInfoShippingCarrierAcctFormControl.invalid">{{_errorMessages[_shippingInfoShippingCarrierAcctName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Delivery Instructions</mat-label>
            <mf-input [formControl]="_shippingInfoDeliveryInstructionsFormControl" />
            <mat-error
                       *ngIf="_shippingInfoDeliveryInstructionsFormControl.invalid">{{_errorMessages[_shippingInfoDeliveryInstructionsName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <div style="display: flex;">
            <mat-label>Shelf Life</mat-label>
            <mat-label class="mf-default-margin-left">{{slider.value}}%</mat-label>
          </div>
          <mat-slider class="example-margin"
                      [max]="100"
                      [min]="0"
                      [step]="5"
                      [discrete]="true"
                      [showTickMarks]="true">
            <input matSliderThumb
                   [formControl]="_shippingInfoShelfLifeFormControl"
                   #slider>
          </mat-slider>
        </div>
      </div>
      <mat-action-row>
        <button color="primary"
                mat-flat-button
                (click)="_prevStep()">Previous</button>
        <button color="primary"
                mat-flat-button
                (click)="_nextStep()">Next</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel #panel4
                         [expanded]="_step() === 4"
                         (opened)="_onExpansionPanelOpened(4, panel4)"
                         (closed)="_onExpansionPanelClosed(4, panel4)"
                         hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="cm-form-group-title">
            Accounts<div class="cm-form-group-title-error">{{ _accountsFormGroup.invalid ? "*" : "" }}</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div #panelContainer4
           class="cm-form-group-container"
           [formGroup]="_accountsFormGroup">
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Payable Contact - Name</mat-label>
            <mf-input [formControl]="_accountsPayableContactNameFormControl" />
            <mat-error
                       *ngIf="_accountsPayableContactNameFormControl.invalid">{{_errorMessages[_accountsPayableContactNameName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Payable Contact - Email Address</mat-label>
            <mf-input [formControl]="_accountsPayableContactEmailAddressFormControl" />
            <mat-error
                       *ngIf="_accountsPayableContactEmailAddressFormControl.invalid">{{_errorMessages[_accountsPayableContactEmailAddressName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Payable Contact - Phone Number</mat-label>
            <mf-country-select matPrefix
                               #accountsPayableContactPhoneNumberCountrySelect></mf-country-select>
            <mf-phone-number-input [formControl]="_accountsPayableContactPhoneNumberFormControl"
                                   [countrySelect]="accountsPayableContactPhoneNumberCountrySelect"></mf-phone-number-input>
            <mat-error
                       *ngIf="_accountsPayableContactPhoneNumberFormControl.invalid">{{_errorMessages[_accountsPayableContactPhoneNumberName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Email Address to Receive Invoices by Email</mat-label>
            <mf-input [formControl]="_accountsReceiveInvoicesEmailFormControl" />
            <mat-error
                       *ngIf="_accountsReceiveInvoicesEmailFormControl.invalid">{{_errorMessages[_accountsReceiveInvoicesEmailName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-label>Payment Terms Requested</mat-label>
          <mat-radio-group [formControl]="_accountsPaymentTermsRequestedFormControl">
            <mat-radio-button [value]="_paymentTermsTypes.creditCard">
              {{_getPaymentTermsTypesDisplayValue(_paymentTermsTypes.creditCard)}}
            </mat-radio-button>
            <mat-radio-button [value]="_paymentTermsTypes.cashInAdvance">
              {{_getPaymentTermsTypesDisplayValue(_paymentTermsTypes.cashInAdvance)}}
            </mat-radio-button>
            <mat-radio-button [value]="_paymentTermsTypes.credit">
              {{_getPaymentTermsTypesDisplayValue(_paymentTermsTypes.credit)}}
            </mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_accountsPaymentTermsRequestedFormControl.invalid">{{_errorMessages[_accountsPaymentTermsRequestedName]}}</mat-error>
        </div>
        <div *ngIf="_showAccountsCreditLimitFormControl"
             class="cm-form-control-container-row">
          <mat-form-field class="mf-default-margin-right">
            <mat-label>Requested Credit Limit (USD)</mat-label>
            <mat-select [formControl]="_accountsCreditLimitFormControl"
                        required>
              <mat-option *ngFor="let limit of _creditLimits"
                          [value]="limit.value">{{limit.label}}</mat-option>
            </mat-select>
            <mat-error
                       *ngIf="_accountsCreditLimitFormControl.invalid">{{_errorMessages[_accountsCreditLimitName]}}</mat-error>
          </mat-form-field>
          <mat-form-field *ngIf="_showAccountsCreditLimitOtherFormControl"
                          class="mf-default-margin-left">
            <mat-label>Requested Credit Limit (USD)</mat-label>
            <mf-input [formControl]="_accountsCreditLimitOtherFormControl" />
            <mat-error
                       *ngIf="_accountsCreditLimitOtherFormControl.invalid">{{_errorMessages[_accountsCreditLimitOtherName]}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button color="primary"
                mat-flat-button
                (click)="_prevStep()">Previous</button>
        <button color="primary"
                mat-flat-button
                (click)="_nextStep()">Next</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel #panel5
                         [expanded]="_step() === 5"
                         (opened)="_onExpansionPanelOpened(5, panel5)"
                         (closed)="_onExpansionPanelClosed(5, panel5)"
                         hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="cm-form-group-title">
            Financial<div class="cm-form-group-title-error">{{ _financialFormGroup.invalid ? "*" : "" }}</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div #panelContainer5
           class="cm-form-group-container"
           [formGroup]="_financialFormGroup">
        <div *ngIf="_showFinancialTotalAssetsFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Total Assets</mat-label>
            <mf-input allowEmptyDisplayValue="true"
                      [type]="_inputTypes.decimal"
                      [formControl]="_financialTotalAssetsFormControl" />
            <mat-error
                       *ngIf="_financialTotalAssetsFormControl.invalid">{{_errorMessages[_financialTotalAssetsName]}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="_showFinancialTotalLiabilitiesFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Total Liabilities</mat-label>
            <mf-input allowEmptyDisplayValue="true"
                      [type]="_inputTypes.decimal"
                      [formControl]="_financialTotalLiabilitiesFormControl" />
            <mat-error
                       *ngIf="_financialTotalLiabilitiesFormControl.invalid">{{_errorMessages[_financialTotalLiabilitiesName]}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="_showFinancialTotalEquityFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Total Equity</mat-label>
            <mf-input allowEmptyDisplayValue="true"
                      [type]="_inputTypes.decimal"
                      [formControl]="_financialTotalEquityFormControl" />
            <mat-error
                       *ngIf="_financialTotalEquityFormControl.invalid">{{_errorMessages[_financialTotalEquityName]}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="_showFinancialTotalDebtFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Total Debt</mat-label>
            <mf-input allowEmptyDisplayValue="true"
                      [type]="_inputTypes.decimal"
                      [formControl]="_financialTotalDebtFormControl" />
            <mat-error
                       *ngIf="_financialTotalDebtFormControl.invalid">{{_errorMessages[_financialTotalDebtName]}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="_showFinancialCurrentAssetsFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Current Assets</mat-label>
            <mf-input allowEmptyDisplayValue="true"
                      [type]="_inputTypes.decimal"
                      [formControl]="_financialCurrentAssetsFormControl" />
            <mat-error
                       *ngIf="_financialCurrentAssetsFormControl.invalid">{{_errorMessages[_financialCurrentAssetsName]}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="_showFinancialCurrentLiabilitiesFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Current Liabilities</mat-label>
            <mf-input allowEmptyDisplayValue="true"
                      [type]="_inputTypes.decimal"
                      [formControl]="_financialCurrentLiabilitiesFormControl" />
            <mat-error
                       *ngIf="_financialCurrentLiabilitiesFormControl.invalid">{{_errorMessages[_financialCurrentLiabilitiesName]}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="_showFinancialTotalInventoryFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Total Inventory</mat-label>
            <mf-input allowEmptyDisplayValue="true"
                      [type]="_inputTypes.decimal"
                      [formControl]="_financialTotalInventoryFormControl" />
            <mat-error
                       *ngIf="_financialTotalInventoryFormControl.invalid">{{_errorMessages[_financialTotalInventoryName]}}</mat-error>
          </mat-form-field>
        </div>
        <div *ngIf="_showFinancialQuestionsFormControl"
             class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Currency Used for Financial Questions Above</mat-label>
            <mf-input [formControl]="_financialQuestionsFormControl" />
            <mat-error
                       *ngIf="_financialQuestionsFormControl.invalid">{{_errorMessages[_financialQuestionsName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Tax ID</mat-label>
            <mf-input [formControl]="_financialTaxIDFormControl" />
            <mat-error *ngIf="_financialTaxIDFormControl.invalid">{{_errorMessages[_financialTaxIDName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-label>Certificate Requirement</mat-label>
          <div>A Tax Exemption, Resale Certificate or Direct Pay Permit is required for shipping addresses in states or
            provinces where sales tax is collected. </div>
          <mat-radio-group [formControl]="_financialCertificateRequirementFormControl">
            <mat-radio-button [value]="true">Yes (Applicable)</mat-radio-button>
            <mat-radio-button [value]="false">No (Taxable)</mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_financialCertificateRequirementFormControl.invalid">{{_errorMessages[_financialCertificateRequirementName]}}</mat-error>
        </div>
        <div *ngIf="_showFinancialFileUpload"
             class="cm-form-control-container">
          <mf-file-upload></mf-file-upload>
        </div>
      </div>

      <mat-action-row>
        <button color="primary"
                mat-flat-button
                (click)="_prevStep()">Previous</button>
        <button color="primary"
                mat-flat-button
                (click)="_nextStep()">Next</button>
      </mat-action-row>
    </mat-expansion-panel>
    <mat-expansion-panel #panel6
                         [expanded]="_step() === 6"
                         (opened)="_onExpansionPanelOpened(6, panel6)"
                         (closed)="_onExpansionPanelClosed(6, panel6)"
                         hideToggle>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <div class="cm-form-group-title">
            Terms and Conditions / Signature<div class="cm-form-group-title-error">{{
              _termsAndConditionsFormGroup.invalid ? "*" : ""
              }}</div>
          </div>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div #panelContainer7
           class="cm-form-group-container"
           [formGroup]="_termsAndConditionsFormGroup">
        <div class="cm-form-control-container">
          <mat-label>E-Commerce Platform</mat-label>
          <div>Would you like to register for our e-commerce platform. <br>Subject to approval</div>
          <mat-radio-group [formControl]="_termsAndConditionsRegisterECommFormControl">
            <mat-radio-button value="true">Yes</mat-radio-button>
            <mat-radio-button value="false">No</mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_termsAndConditionsRegisterECommFormControl.invalid">{{_errorMessages[_termsAndConditionsRegisterECommName]}}</mat-error>
        </div>

        <div class="cm-form-control-container">
          <mat-label>Incora Terms and Conditions of Sale</mat-label>
          <div>Terms & Conditions | Incora
            *A printed copy of our current Terms and Conditions can be requested from your sales representative

            *On behalf of the Customer identified above, I the undersigned acknowledge that (i) the Customer agrees to
            the Incora
            Terms and Conditions and of Sale; (ii) Customer certifies its compliance with all applicable export and
            sanction or
            embargo laws and will not export, re-export, supply, transfer, or divert the items received from Incora in
            violation of
            any applicable laws. Customer also agrees to assume all liability for any routed export shipments for which
            the Customer
            is responsible; and (iii) I am the duly authorized representative to sign this form.</div>
          <mat-radio-group [formControl]="_termsAndConditionsAcceptedFormControl">
            <mat-radio-button value="true">Accept</mat-radio-button>
          </mat-radio-group>
          <mat-error
                     *ngIf="_termsAndConditionsAcceptedFormControl.invalid">{{_errorMessages[_termsAndConditionsAcceptedName]}}</mat-error>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Print Legal Name of Company</mat-label>
            <mf-input [formControl]="_termsAndConditionsLegalNameCompanyFormControl" />
            <mat-error
                       *ngIf="_termsAndConditionsLegalNameCompanyFormControl.invalid">{{_errorMessages[_termsAndConditionsLegalNameCompanyName]}}</mat-error>
          </mat-form-field>
        </div>
        <div class="cm-form-control-container">
          <mat-form-field>
            <mat-label>Print Name & Title of Authorized Party</mat-label>
            <mf-input [formControl]="_termsAndConditionsAuthorizedPartyFormControl" />
            <mat-error
                       *ngIf="_termsAndConditionsAuthorizedPartyFormControl.invalid">{{_errorMessages[_termsAndConditionsAuthorizedPartyName]}}</mat-error>
          </mat-form-field>
        </div>
      </div>
      <mat-action-row>
        <button color="primary"
                mat-flat-button
                (click)="_prevStep()">Previous</button>
        <button color="primary"
                mat-flat-button
                [disabled]="_customerInfoFormGroup.invalid || 
                _billingAddressFormGroup.invalid || 
                _shippingAddressFormGroup.invalid || 
                _shippingInfoFormGroup.invalid || 
                _accountsFormGroup.invalid || 
                _financialFormGroup.invalid || 
                _termsAndConditionsFormGroup.invalid"
                (click)="_onSubmitClicked()">Submit</button>
      </mat-action-row>
    </mat-expansion-panel>
  </mat-accordion>
</div>