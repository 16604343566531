import { NgModule } from "@angular/core";
import { ExtraOptions, RouterModule, provideRouter, withEnabledBlockingInitialNavigation } from "@angular/router";
import { EComCartInvoiceComponent } from "@e-commerce/cart/invoice/cart.invoice.component";
import { EComCartOrderComponent } from "@e-commerce/cart/order/cart.order.component";
import { EComInvoiceBillingPaymentComponent } from "@e-commerce/checkout/invoice/billingPayment/invoice.billing.payment.component";
import { EComCheckoutInvoiceComponent } from "@e-commerce/checkout/invoice/checkout.invoice.component";
import { EComInvoiceOrderReviewComponent } from "@e-commerce/checkout/invoice/orderReview/invoice.order.review.component";
import { EComOrderBillingPaymentComponent } from "@e-commerce/checkout/order/billingPayment/order.billing.payment.component";
import { EComCheckoutOrderComponent } from "@e-commerce/checkout/order/checkout.order.component";
import { EComOrderReviewComponent } from "@e-commerce/checkout/order/orderReview/order.review.component";
import { EComOrderShippingMethodComponent } from "@e-commerce/checkout/order/shippingMethod/order.shipping.method.component";

import { ECOM_BREADCRUMB_KEY_CART, ECOM_BREADCRUMB_KEY_CHECKOUT, ECOM_BREADCRUMB_KEY_CONFIRMATION, ECOM_BREADCRUMB_KEY_CUSTOMER, ECOM_BREADCRUMB_KEY_HOME, ECOM_BREADCRUMB_KEY_INVOICES, ECOM_BREADCRUMB_KEY_INVOICE_DETAILS, ECOM_BREADCRUMB_KEY_NEW, ECOM_BREADCRUMB_KEY_ORDERS, ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS, ECOM_BREADCRUMB_KEY_SHOP } from "@e-commerce/ecom.route.keys";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { EComHomeComponent } from "@e-commerce/home/home.component";
import { EComInvoiceDetailComponent } from "@e-commerce/invoiceDetail/invoice.detail.component";
import { EComInvoiceDetailOrdersComponent } from "@e-commerce/invoiceDetailOrders/invoice.detail.orders.component";
import { EComInvoicesComponent } from "@e-commerce/invoices/invoices.component";
import { EComConfirmationComponent } from "@e-commerce/landing/confirmation/confirmation.component";
import { EComNotApprovedComponent } from "@e-commerce/landing/notApproved/not.approved.component";
import { EComPaypalCancelComponent } from "@e-commerce/landing/paypal/cancel/paypal.cancel.component";
import { EComPaypalErrorComponent } from "@e-commerce/landing/paypal/error/paypal.error.component";
import { EComPaypalReturnComponent } from "@e-commerce/landing/paypal/return/paypal.return.component";
import { EComOrdersComponent } from "@e-commerce/orders/orders.component";
import { EComProductDetailComponent } from "@e-commerce/productDetail/product.detail.component";
import { EComProductsComponent } from "@e-commerce/products/products.component";
import { EComRootAuthnComponent } from "@e-commerce/root/authn/root.authn.component";
import { EComRootUnAuthnComponent } from "@e-commerce/root/unauthn/root.unauthn.component";
import { MfRoutes, MF_UNAUTHN_ROUTES, MfAuthGuardService, MfFillRouterOutletComponent } from "@pattonair/material-framework";
import { EComCartModes } from "./common/cart.modes";
import { EComRecommendedProductsAComponent } from "./products/recommended/recommended.products.a.component";
import { EComNewCustomerSetupCompleteComponent } from "./customer/newCustomerSetup/new.customer.setup.complete.component";
import { EComNewCustomerSetupComponent } from "./customer/newCustomerSetup/new.customer.setup.component";
import { EComTestComponent } from "./test/test.component";


const ROUTING_CONFIGURATION: ExtraOptions = {
  paramsInheritanceStrategy: "always"
};

const ECOM_ROUTES: MfRoutes = [
  {
    path: "",
    redirectTo: EComRouteNames.routeHome,
    pathMatch: "full",
  },
  {
    component: EComRootUnAuthnComponent,
    path: "",
    children: [
      {
        path: EComRouteNames.partNameNotApproved,
        component: EComNotApprovedComponent,
      },
      ...MF_UNAUTHN_ROUTES,
    ],
  },
  {
    component: EComRootAuthnComponent,
    path: "",
    children: [
      {
        path: EComRouteNames.partNameHome,
        component: EComHomeComponent,
        canActivate: [MfAuthGuardService],
        breadcrumb: {
          alias: ECOM_BREADCRUMB_KEY_HOME,
          label: "Home",
        },
      },
      {
        path: EComRouteNames.partNamePaypalReturnOrder,
        component: EComPaypalReturnComponent,
        data: {
          mode: EComCartModes.order,
        },
      },
      {
        path: EComRouteNames.partNamePaypalCancelOrder,
        component: EComPaypalCancelComponent,
        data: {
          mode: EComCartModes.order,
        },
      },
      {
        path: EComRouteNames.partNamePaypalErrorOrder,
        component: EComPaypalErrorComponent,
        data: {
          mode: EComCartModes.order,
        },
      },
      {
        path: EComRouteNames.partNamePaypalReturnInvoice,
        component: EComPaypalReturnComponent,
        data: {
          mode: EComCartModes.invoice,
        },
      },
      {
        path: EComRouteNames.partNamePaypalCancelInvoice,
        component: EComPaypalCancelComponent,
        data: {
          mode: EComCartModes.invoice,
        },
      },
      {
        path: EComRouteNames.partNamePaypalErrorInvoice,
        component: EComPaypalErrorComponent,
        data: {
          mode: EComCartModes.invoice,
        },
      },
      {
        path: "test",
        component: EComTestComponent,
      },
      {
        path: "",
        component: MfFillRouterOutletComponent,
        canActivate: [MfAuthGuardService],
        data: {
          mode: EComCartModes.invoice,
        },
        breadcrumb: {
          alias: ECOM_BREADCRUMB_KEY_HOME,
          label: "Home",
          routeInterceptor: () => EComRouteNames.routeHome,
        },
        children: [
          {
            path: "",
            redirectTo: EComRouteNames.partNameOrders,
            pathMatch: "full",
          },
          {
            path: EComRouteNames.partNameOrders,
            component: MfFillRouterOutletComponent,
            canActivate: [MfAuthGuardService],
            breadcrumb: {
              alias: ECOM_BREADCRUMB_KEY_ORDERS,
              label: "Orders",
            },
            children: [
              {
                path: "",
                redirectTo: EComRouteNames.partNameList,
                pathMatch: "full",
              },
              {
                path: EComRouteNames.partNameList,
                component: EComOrdersComponent,
                canActivate: [MfAuthGuardService],
              },
            ]
          },
          {
            path: EComRouteNames.partNameInvoices,
            component: MfFillRouterOutletComponent,
            canActivate: [MfAuthGuardService],
            breadcrumb: {
              alias: ECOM_BREADCRUMB_KEY_INVOICES,
              label: "Invoices",
              routeInterceptor: () => EComRouteNames.routeInvoices,
            },
            children: [
              {
                path: "",
                redirectTo: EComRouteNames.partNameList,
                pathMatch: "full",
              },
              {
                path: EComRouteNames.partNameList,
                component: EComInvoicesComponent,
                canActivate: [MfAuthGuardService],
              },
              {
                path: EComRouteNames.partNameInvoiceDetailItemKey,
                component: EComInvoiceDetailComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_INVOICE_DETAILS,
                  label: "Detail",
                },
              },
              {
                path: EComRouteNames.partNameInvoiceDetailItemKey,
                component: EComInvoiceDetailOrdersComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_INVOICE_DETAILS,
                  label: "Detail",
                  routeInterceptor: (routeLink: string) => {
                    return "invoices/" + routeLink;
                  },
                },
                children: [
                  {
                    path: EComRouteNames.partNameOrders,
                    component: MfFillRouterOutletComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_ORDERS,
                      label: "Orders",
                    },
                    children: [
                      {
                        path: "",
                        redirectTo: EComRouteNames.partNameList,
                        pathMatch: "full",
                      },
                      {
                        path: EComRouteNames.partNameList,
                        component: EComOrdersComponent,
                        canActivate: [MfAuthGuardService],
                      },
                    ]
                  },
                ]
              },
              // {
              //   path: EComRouteNames.partNameCart,
              //   component: MfFillRouterOutletComponent,
              //   canActivate: [MfAuthGuardService],
              //   breadcrumb: {
              //     alias: ECOM_BREADCRUMB_KEY_CART,
              //     label: "Cart",
              //     routeInterceptor: () => EComRouteNames.routeInvoiceCartList
              //   },
              //   children: [
              //     {
              //       path: "",
              //       redirectTo: EComRouteNames.routeInvoiceCartList,
              //       pathMatch: "full",
              //     },
              //     {
              //       path: EComRouteNames.partNameList,
              //       component: EComCartInvoiceComponent,
              //       canActivate: [MfAuthGuardService],
              //     },
              //     {
              //       path: EComRouteNames.partNameCheckout,
              //       component: EComCheckoutInvoiceComponent,
              //       canActivate: [MfAuthGuardService],
              //       breadcrumb: {
              //         alias: ECOM_BREADCRUMB_KEY_CHECKOUT,
              //         label: "Checkout",
              //       },
              //       children: [
              //         {
              //           path: "",
              //           redirectTo: EComRouteNames.partNameBillingPayment,
              //           pathMatch: "full",
              //         },
              //         {
              //           path: EComRouteNames.partNameBillingPayment,
              //           component: EComInvoiceBillingPaymentComponent,
              //           canActivate: [MfAuthGuardService],
              //         },
              //         {
              //           path: EComRouteNames.partNameReview,
              //           component: EComInvoiceOrderReviewComponent,
              //           canActivate: [MfAuthGuardService],
              //         }
              //       ]
              //     },
              //   ]
              // },
            ]
          },
        ]
      },
      {
        path: "",
        component: MfFillRouterOutletComponent,
        canActivate: [MfAuthGuardService],
        data: {
          mode: EComCartModes.order,
        },
        breadcrumb: {
          alias: ECOM_BREADCRUMB_KEY_HOME,
          label: "Home",
          routeInterceptor: () => EComRouteNames.routeHome
        },
        children: [
          {
            path: EComRouteNames.partNameShop,
            component: MfFillRouterOutletComponent,
            canActivate: [MfAuthGuardService],
            breadcrumb: {
              alias: ECOM_BREADCRUMB_KEY_SHOP,
              label: "Shop",
              routeInterceptor: () => EComRouteNames.routeProductsList
            },
            children: [
              {
                path: "",
                redirectTo: EComRouteNames.routeShopCart,
                pathMatch: "full",
              },
              {
                path: EComRouteNames.partNameConfirmation,
                component: EComConfirmationComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_CONFIRMATION,
                  label: "Confirmation",
                },
              },
              {
                path: EComRouteNames.partNameCart,
                component: MfFillRouterOutletComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_CART,
                  label: "Cart",
                  routeInterceptor: () => EComRouteNames.routeShopCartList
                },
                children: [
                  {
                    path: "",
                    redirectTo: EComRouteNames.routeShopCartList,
                    pathMatch: "full",
                  },
                  {
                    path: EComRouteNames.partNameList,
                    component: EComCartOrderComponent,
                    canActivate: [MfAuthGuardService],
                  },
                  {
                    path: EComRouteNames.partNameCheckout,
                    component: EComCheckoutOrderComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_CHECKOUT,
                      label: "Checkout",
                    },
                    children: [
                      {
                        path: "",
                        redirectTo: EComRouteNames.partNameShippingMethod,
                        pathMatch: "full",
                      },
                      {
                        path: EComRouteNames.partNameShippingMethod,
                        component: EComOrderShippingMethodComponent,
                        canActivate: [MfAuthGuardService],
                      },
                      {
                        path: EComRouteNames.partNameBillingPayment,
                        component: EComOrderBillingPaymentComponent,
                        canActivate: [MfAuthGuardService],
                      },
                      {
                        path: EComRouteNames.partNameReview,
                        component: EComOrderReviewComponent,
                        canActivate: [MfAuthGuardService],
                      }
                    ]
                  },
                ]
              },
              {
                path: EComRouteNames.partNameProducts,
                component: MfFillRouterOutletComponent,
                canActivate: [MfAuthGuardService],
                children: [
                  {
                    path: "",
                    redirectTo: EComRouteNames.partNameList,
                    pathMatch: "full",
                  },
                  {
                    path: EComRouteNames.partNameList,
                    component: EComProductsComponent,
                    canActivate: [MfAuthGuardService],
                  },
                  {
                    path: EComRouteNames.partNameParameterProductDetailItemSpecKey,
                    component: EComProductDetailComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS,
                      label: "Product Detail",
                    },
                  },
                  {
                    path: EComRouteNames.partNameParameterProductDetailItemKey,
                    component: EComProductDetailComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS,
                      label: "Product Detail",
                    },
                  },
                  {
                    path: EComRouteNames.partNameReccomended,
                    component: EComRecommendedProductsAComponent,
                    canActivate: [MfAuthGuardService],
                    breadcrumb: {
                      alias: ECOM_BREADCRUMB_KEY_PRODUCT_DETAILS,
                      label: "Recommended",
                    },
                  }
                ]
              },
            ]
          },
        ],
      },
      {
        path: "",
        component: MfFillRouterOutletComponent,
        canActivate: [MfAuthGuardService],
        breadcrumb: {
          alias: ECOM_BREADCRUMB_KEY_HOME,
          label: "Home",
          routeInterceptor: () => EComRouteNames.routeHome
        },
        children: [
          {
            path: EComRouteNames.partNameCustomer,
            component: MfFillRouterOutletComponent,
            canActivate: [MfAuthGuardService],
            breadcrumb: {
              alias: ECOM_BREADCRUMB_KEY_CUSTOMER,
              label: "Customer"
            },
            children: [
              {
                path: "",
                redirectTo: EComRouteNames.routeCustomerNew,
                pathMatch: "full",
              },
              {
                path: EComRouteNames.partNameParameterNewConfirmationApplicationId,
                component: EComNewCustomerSetupCompleteComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_CONFIRMATION,
                  label: "Setup Confirmation",
                },
              },
              {
                path: EComRouteNames.partNameNew,
                component: EComNewCustomerSetupComponent,
                canActivate: [MfAuthGuardService],
                breadcrumb: {
                  alias: ECOM_BREADCRUMB_KEY_NEW,
                  label: "New",
                },
              },
            ],
          }
        ]
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(ECOM_ROUTES, ROUTING_CONFIGURATION)],
  exports: [RouterModule],
  providers: [
    provideRouter(ECOM_ROUTES, withEnabledBlockingInitialNavigation())
  ]
})
export class EComRoutingModule { }
