import { Component, Inject, Injector, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { Router } from "@angular/router";
import { EComCartModes } from "@e-commerce/common/cart.modes";
import { ECOM_ICONS_CONFIG_TOKEN, ECOM_TABLE_CONFIG, EComIconsConfig } from "@e-commerce/ecom.config";
import { EComRouteNames } from "@e-commerce/ecom.route.names";
import { ECOM_ENVIRONMENT } from "@e-commerce/environments/environment";
import { EComManufacturerDetailLineComponent } from "@e-commerce/manufacturerDetailLine/manufacturer.detail.line.component";
import { EComCustomerModel } from "@e-commerce/modelConfigs/customer.model.config";
import { EComManufacturerModel } from "@e-commerce/modelConfigs/manufacturer.model.config";
import { EComModelsConfig } from "@e-commerce/modelConfigs/model.config";
import { EComStockSummeryModel, EComStockSummeryRequest } from "@e-commerce/modelConfigs/stock.summery.model.config";
import { EComCartOrderService } from "@e-commerce/services/cart.order.service";
import { EComCustomerService } from "@e-commerce/services/customer.service";
import { EComManufacturerService } from "@e-commerce/services/manufacturer.service";
import { MfTypeInfo, MfTableConfig, MfModelFieldExtendedConfig, MF_STRING_FILTER_CONFIG, mfObjectGetPropertyPath, MF_TABLE_CONFIG_TOKEN, MfBaseComponent, MfPortalsTableComponent, MfInputComponent, MfModelConfigMapped, MFModelConfigFieldKey, MfFilterOperatorTypes, MfModelConfigService, MfFilterService, mfTypeIsUndefined, mfTableReIndexModelFieldExtendedConfig, MfTableSimpleSearchComponent, MfTableSimpleSearchEvent } from "@pattonair/material-framework";
import { Observable, Subscription, map } from "rxjs";
import { EComCartModeService } from "../services/cart.mode.service";
import { GoogleAnalyticsService } from "ngx-google-analytics";

const TYPE_INFO: MfTypeInfo = { className: "EComProductsComponent" };

const ECOM_PRODUCTS_DETAIL_TABLE_CONFIG: MfTableConfig = {
  ...ECOM_TABLE_CONFIG,
  row: {
    ...ECOM_TABLE_CONFIG.row,
    clickAction: {
      ...ECOM_TABLE_CONFIG.row.clickAction,
      tooltip: "View Product Detail",
    }
  }
};

const ECOM_PRODUCTS_LOCATION_KEY = "products";

const ECOM_STOCK_SUMMERY_EXTENDED_MODEL_CONFIG: MfModelFieldExtendedConfig[] = [
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG, input: { string: { maxLength: 25 } } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("site")
  },
  {
    table: { index: 0, header: { sortable: false } },
    filter: { ...MF_STRING_FILTER_CONFIG, input: { string: { maxLength: 25 } } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("itemCode")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("itemDescription")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("manufacturerName")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("certificateDescription")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("revision")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("shelfLifeDays")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("unitOfMeasure")
  },
  {
    table: { index: 0, header: { sortable: false } },
    fieldPath: mfObjectGetPropertyPath<EComStockSummeryModel>("quantityAvailable")
  },
];

type EComManufacturerViewModel = EComManufacturerModel & {
  displayValue?: string;
};


@Component({
  selector: "ecom-products",
  templateUrl: "./products.component.html",
  styleUrls: ["./products.component.scss"],
  providers: [
    { provide: MF_TABLE_CONFIG_TOKEN, useValue: ECOM_PRODUCTS_DETAIL_TABLE_CONFIG },
  ]
})
export class EComProductsComponent extends MfBaseComponent implements OnInit {
  @ViewChild("stockSummeryTable", { static: true })
  protected get _stockSummeryTable(): MfPortalsTableComponent<EComStockSummeryModel, string, number> | undefined {
    return this._stockSummeryTableInt;
  }
  protected set _stockSummeryTable(value: MfPortalsTableComponent<EComStockSummeryModel, string, number> | undefined) {
    this._stockSummeryTableInt = value;
    this._initializeTable();
  }

  @ViewChild("partsSearch", { static: true })
  protected _partsSearch?: MfInputComponent;

  @ViewChildren("manufacturerDetails")
  protected _manufacturerDetails?: QueryList<EComManufacturerDetailLineComponent>;

  protected _stockSummeryModelConfig: MfModelConfigMapped;
  protected _stockSummeryRequestModelConfig: MfModelConfigMapped;
  protected _stockSummeryTableInt?: MfPortalsTableComponent<EComStockSummeryModel, string, number>;
  protected _itemCodeFieldPath: MFModelConfigFieldKey;
  protected _selectedCustomer?: EComCustomerModel;
  protected _manufacturer?: EComManufacturerViewModel;
  protected _manufacturerIsLoading = false;
  protected _manufacturers?: EComManufacturerViewModel[];
  protected _geManufacturerDetailSub?: Subscription;
  protected _locationKey = ECOM_PRODUCTS_LOCATION_KEY;
  protected _itemCodeOperator = MfFilterOperatorTypes.startsWith;


  public constructor(
    protected override _injector: Injector,
    protected _router: Router,
    protected _modelConfigService: MfModelConfigService,
    protected _cartModeService: EComCartModeService,
    protected _cartOrderService: EComCartOrderService,
    protected _filterService: MfFilterService,
    protected _customerService: EComCustomerService,
    protected _manufacturerService: EComManufacturerService,
    protected _googleAnalyticsService: GoogleAnalyticsService,
    @Inject(ECOM_ICONS_CONFIG_TOKEN)
    protected _iconsConfig: EComIconsConfig,
  ) {
    super(TYPE_INFO, _injector);
    this._cartModeService.setMode(EComCartModes.order);
    this._stockSummeryModelConfig = this._modelConfigService.get<EComModelsConfig>("stockSummery");
    this._stockSummeryRequestModelConfig = this._modelConfigService.get<EComModelsConfig>("stockSummeryRequest");
    this._itemCodeFieldPath = mfObjectGetPropertyPath<EComStockSummeryModel>("itemCode");
    this._setModelConfigs();
    this._sub(this._customerService.onSelectedChange, { next: () => this._tableLoadData() });
  }

  public ngOnInit(): void {
    this._sub(this._customerService.hasSelection, {
      next: (hasSelection) => {
        if (hasSelection) {
          this._tableLoadData();
        }
      }
    });
  }

  protected get _isLoading(): boolean {
    return this._stockSummeryTable?.isLoading === true;
  }

  protected _tableLoadData(): void {
    if (!mfTypeIsUndefined(this._stockSummeryTable)) {
      this._manufacturers = [];
      this._stockSummeryTable.blockDataLoad = false;
      this._stockSummeryTable.loadData();
      this._cdRef.detectChanges();
    }
  }

  protected _onItemDetailExpand(product: EComStockSummeryModel): void {
    this._manufacturerDetails?.forEach(i => {
      if (i.stockSummery?.itemSpecKey === product.itemSpecKey) {
        i.loadDetail();
      }
    });
  }

  protected _addToCard(product: EComStockSummeryModel): void {
    this._stockSummeryTable?.slideOutActionsClose();
    const loading = this._subLoading(
      this._cartOrderService.add({ itemSpecKey: product.itemSpecKey }),
      {
        next: () => {
          loading.complete();
          this._router.navigate([EComRouteNames.routeShopCartList]);
        },
        error: () => {
          loading.complete();
        }
      },
      "Adding Item To Cart"
    );
  }

  protected _onRowClicked(product: EComStockSummeryModel): void {
    this._router.navigate([EComRouteNames.getProductDetailRouteItemSpecKey(product.itemKey, product.itemSpecKey)]);
  }

  protected _onSearch(event: MfTableSimpleSearchEvent): void {
    if (!mfTypeIsUndefined(this._stockSummeryTable)) {
      this._googleAnalyticsService.event("search_product_code", "products_list", event.value as string);
      this._tableLoadData();
    }
  }

  protected _setModelConfigs(): void {
    mfTableReIndexModelFieldExtendedConfig(ECOM_STOCK_SUMMERY_EXTENDED_MODEL_CONFIG);
    this._modelConfigService.setExtendedConfigs(this._stockSummeryModelConfig, ECOM_STOCK_SUMMERY_EXTENDED_MODEL_CONFIG);
  }

  protected _initializeTable(): void {
    if (!mfTypeIsUndefined(this._stockSummeryTable)) {
      this._stockSummeryTable.blockDataLoad = true;
      this._stockSummeryTable.dataSource.url = `${ECOM_ENVIRONMENT.portalsCustomerRootUrl}/${ECOM_ENVIRONMENT.portalsStocksUrl}`;

      this._stockSummeryTable.dataSource.buildPostData = this._updatePostDate;
    }
  }

  protected _updatePostDate = (data: EComStockSummeryRequest): Observable<EComStockSummeryRequest> => {
    return this._customerService.selected.pipe(
      map((selected) => {
        data.key = selected.key;
        return data;
      })
    );
  };
}